<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(id)="{ item }">
            <p class="m-0 underline" @click="editPromocode(item)">
              {{ item.id }}
            </p>
          </template>

          <template #cell(name)="{ item }">
            <p class="m-0">
              {{ item.name || "-" }}
            </p>
          </template>

          <template #cell(eventName)="{ item }">
            <p class="m-0">
              {{ item.eventName || "-" }}
            </p>
          </template>

          <template #cell(startDate)="{ item }">
            <p class="m-0">
              {{
                item.startDate
                  ? $moment(item.startDate).locale("th").format("DD/MM/YYYY")
                  : "-"
              }}
            </p>
            <p class="m-0" v-if="item.startDate">
              {{ $moment(item.startDate).locale("th").format("(HH:mm)") }}
            </p>
          </template>

          <template #cell(endDate)="{ item }">
            <p class="m-0">
              {{
                item.endDate
                  ? $moment(item.endDate).locale("th").format("DD/MM/YYYY")
                  : "-"
              }}
            </p>
            <p class="m-0" v-if="item.endDate">
              {{ $moment(item.endDate).locale("th").format("(HH:mm)") }}
            </p>
          </template>

          <template #cell(isActive)="{ item }">
            <p
              class="m-0"
              :class="item.isActive == 0 ? 'text-error' : 'text-success'"
            >
              {{ item.isActive == 0 ? "Inactive" : "Active" }}
            </p>
          </template>

          <template #cell(actions)="{ item }">
            <div class="container">
              <font-awesome-icon
                class="mr-2 pointer"
                icon="pen"
                @click="editPromocode(item)"
              />
              <b-form-checkbox
                v-model="item.isActive"
                switch
                :value="1"
                :unchecked-value="0"
                @change="switchActivePromocode(item)"
              >
              </b-form-checkbox>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    async switchActivePromocode(obj) {
      let { id, isActive } = obj;

      const switchActive = await this.$services.promocode.switchActivePromocode(
        id,
        isActive
      );

      if (switchActive.result == 1) {
        this.$emit("successSwitch", switchActive.detail.message);
      }
    },
    editPromocode(obj) {
      let { id, promocodeTypeId } = obj;

      this.$router.push(`/promocode/details/${id}?type=${promocodeTypeId}`);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-danger";
      else return "text-secondary";
    },
    chkBirthday(birthday) {
      let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.container {
  min-height: 100px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
